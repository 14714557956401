import { render, staticRenderFns } from "./departmentCreate.vue?vue&type=template&id=5bc0eaa2&scoped=true&lang=html&"
import script from "./departmentCreate.vue?vue&type=script&lang=js&"
export * from "./departmentCreate.vue?vue&type=script&lang=js&"
import style0 from "./departmentCreate.vue?vue&type=style&index=0&id=5bc0eaa2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bc0eaa2",
  null
  
)

export default component.exports